.contact {
  .container p {
    // color: white;
  }
  .boxBgColor {
    background-color: #f4f3f1;
  }
  .vertical-center {
    margin-top: 100px;
  }
  h2 {
    margin: 0 auto;
  }
  p {
    max-width: 400px;
    margin: 0 auto;
    font-size: 1.5rem;
    color : #f3f5f5;
    // color: rgb(121, 126, 126);
  }
  .container-contact {
    max-width: 600px;
  }
  div.p-3 {
    // color:#5FD5F6;
    color:#566063;
  }
}
