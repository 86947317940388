.modalStyling {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 500px) {
  .modalStyling {
    overflow-y: auto;
    height: calc(100% - 1rem);
  }
}
