.navbar {
    height: 70px;
    width:100%;
    background-color: #282C34;
    
    .navigation {

        ul {
            width:100%;
            height:100%;
            text-align: center;

            li {
                display:inline-block;
                padding: 1rem 0 1rem .4rem;
                font-size: 1.3rem;
                position:relative;
                &:hover i {
                    transform: translate(3px, 3px)
                }
            }
             a{
            color:$color-1;
        }
      
        i {
            position: absolute;
            font-size: 0.95rem;
            transform:translateY(3px);
            transition: .2s ease;
        }
        span {
            margin-left: 2.7rem;
        }
        }

        /* Style The Dropdown Button */
.dropbtn {
    display: none;
    background-color: $color-1;
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #ffffff;
  }

        // .btn-menu {
        //     background:url('../../media/menu.svg') ;
        //     position: absolute;
        //     top: 15px;
        //     right:15px;
        //     width:40px;
        //     height:40px;
        //     display: none;
        // }
    }
}

//media queries :

@media screen and (max-width: 1434px) {
    .home, .knowledges, .portfolio, .contact {
        .navbar {
            display:flex;
            min-height: 50px;
            margin-bottom: .2rem;
            width: 100%;

            .navigation {
                padding: .1rem, 1rem;

                ul {
                    display:flex;
                    display : flex;justify-content: space-between;


                    span {
                        margin-left: 1.5rem;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 640px){

    .home, .knowledges, .portfolio, .contact {
        .contentTitle.active-menu{
            display:none;
        }
        .navbar {
            
            .navigation {
                ul {
                    display:none;
                }
              
                ul.active-menu {
                    display:block;
                    position:absolute;
                    
                    li {
                        background-color: #fff;
                        color: black;
                        margin: 0;
                        display:block;
                        top: 4%;
                        left : 50%;
                        transform: translate(-50%, -50%);
                        
                    }
                }
                .dropdown {
                    margin-left: 65vw;
                }
                .dropbtn {
                    display : block;
                }
                // .btn-menu {
                //     display:block;
                    
                // }
            }
        }
    }
}