.knowledges {
  color:white;
  .navbar .navigation {
    height: 70px;
  }
  .centeringContent {
    display: flex;
  }
  h2 {
    color : white;
    text-align: center;
    margin: 40px auto 10px auto;
  }
  .mainContent {
    height: 70vh;
    .subContent {
      min-height : 538px;
    }
    .row>img{
      padding-left : 0;
      padding-right : 0;
    }
  }

 

  .frontend {
    ul.newSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: #01c3d7;
        }
      }

      @for $i from 1 through $front-new {
      li:nth-child(#{$i}) {
        animation-delay: 0.2s * $i;
      }
    }

    }
    
    }
    .frontend {
    ul.oldSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: $color-4;
        }
      }
      @for $i from 1 through $front-old {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
      
  }
  .backend {
    ul.newSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: #01c3d7;
        }
      }
      @for $i from 1 through $back-new {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .backend {
    ul.oldSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: $color-4;
        }
      }
      @for $i from 1 through $back-old {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .databases {
    ul.newSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: #01c3d7;
        }
      }
      @for $i from 1 through $database-new {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .databases {
    ul.oldSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: $color-4;
        }
      }
      @for $i from 1 through $database-old {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .graphism {
    ul {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: #01c3d7;
        }
      }
      @for $i from 1 through $graphism-ui {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .graphism {
    ul.oldSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: $color-4;
        }
      }
      @for $i from 1 through $graphism-ui {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .development {
    ul {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: #01c3d7;
        }
      }
      @for $i from 1 through $devnew {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }
  .development {
    ul.oldSkills {
      li {
        animation: fadeIn 0.5s linear;
        animation-fill-mode: both;
        font-family: $font-2;
        i {
          padding-right: 10px;
          color: $color-4;
        }
      }
      @for $i from 1 through $devold {
        li:nth-child(#{$i}) {
          animation-delay: 0.2s * $i;
        }
      }
    }
  }

  // Keyframe animation
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
      top: 100px;
    }
    75% {
      opacity: 0.5;
      top: 0px;
    }
    100% {
      opacity: 1;
    }
  }
}


@media screen and (max-width : 480px ) {
  .knowledges {
    .mainContent {
      .subContent{
        padding : 0 !important;
      }
    }
  }
}