@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital@1&family=Oxygen:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@500&display=swap');

//polices :
// $font-1: 'Cantarell', sans-serif;
$font-1: 'Readex Pro', sans-serif;
$font-2: 'Oxygen', sans-serif;
//couleurs:
$white: #FFFFFF;
$black: #333;
$color-1 : #61DAFB;
$color-2 : #6C51DC;
$color-3 : #f8f3ee;
$color-4 : #282C34;

//iteration animations knowledges
// c'est le nb d'éléments de la liste
$front-new : 13;
$front-old : 2;
$back-new : 2;
$back-old : 3;
$database-new : 3;
$database-old : 3;
$graphism-ui : 5;
$devnew : 5;
$devold : 6;


//mixin:
@mixin verticalCenter {
    display:flex;
    align-items: center;
    justify-content: center;
}
//reset css:
* , ::before, ::after {
    box-sizing:border-box;
    margin:0;
    padding:0;
}

html, body {
    height : 100vh;
    // font-family: $font-1; change global website font
    background-color: rgb(98, 103, 124);
}
// for counter the bootstrap rule:
ol, ul {
    padding-left: 0;
}

button {
    cursor: pointer;
}
li {
    list-style-type: none;
}
a {
    text-decoration: none;
    color:$black;
    cursor: pointer;
}

.btn-primary {
    border-color: #fff;
    color : $color-1;
    background-color: #282C34;
}
.btn-primary:hover {
    color: #fff;
    background-color: $color-1;
    border-color: #fff;
}