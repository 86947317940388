.portfolio {

    .boxBgColor {
        background-color: #f8f3ee;
      }
    .card {
        transition: transform .2s;
        margin : 30px auto;
        min-width : 280px;
      
         .languages:hover {
        transform: translate(0, 5px);
        }
        h5.card-title {
          min-height : 50px;
        }
        p.card-text {
          font-family: $font-2;
          min-height : 100px;
        }
    }
        .showInfos {
            animation: popup .3s ease forwards ;
            transform-origin: center;
      
            .infosContent {
              position: relative;
              transform: translate(-50%, -50%);
              max-width: 600px;
              min-width : 320px;
              margin: 250px auto;
              color: $color-1;
              background: $black;
              padding: 2rem;
              box-shadow: 0 10px 10px #333;
              animation: popup .6s ease forwards;
              transform: scale(0);
              transform-origin: center;

              .button {
                cursor : pointer;
              }

              @keyframes popup {
                to {
                  transform: scale(1);
                } 
              }
                 .head {
                display: flex;
                justify-content: space-around;
                margin: 1rem 0;

                a {
                    color : $white;
                }
              }
            }
        }  
        .card:hover {
          transform: scale(1.1);
        }
}
            
              
            
            
    
   
        
