.notFound {
  .notFoundContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .goBackLink {
        text-align: center;
        padding-top : 20px;
    }
  }
}
