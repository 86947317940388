.home {
  // background-color: rgb(98, 103, 124);
  .container {
    .logo-container-1 {
      display: none;
    }

    .contentTitle {
      height: auto;
      background: url('../../media/textForm.svg') no-repeat center/contain;
      h1,
      h2 {
        color: white;
        // text-shadow: #6d6a6a 0.8px 0 0.8px;
      }
      p {
        font-size: 1.3rem;
        color : white;
        text-shadow: #000 0.8px 0 0.8px;
        // color: rgb(121, 126, 126);
      }
    }
    .vertical-center {
      margin-top: 150px;
    }
    .socialNetwork {
      display: flex;
      justify-content: center;
      align-items: center;
      color : white;
    }
    .contentRight {
      height: 70vh;
      background: url('../../media/GinDevCode5.svg') no-repeat center/contain;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;

      //react
      .logo-1 {
        // margin-top: 200px;
        margin-top: 20%;
        margin-left: 5%;
      }
      //html5
      .logo-4 {
        margin-top: 10%;
      }
      //css3
      .logo-5 {
        // margin-top: 80px;
        margin-top: 25%;
      }
      //logo js
      .logo-2 {
        // margin-top: -80px;
        margin-top: -10%;
      }
      //nodejs
      .logo-3 {
        // margin-top: -80px;
        margin-top: 5%;
      }
      .logo-6 {
        // margin-top: -80px;
        margin-left: 5%;
      }
    }
    ul > li {
      display: inline;
    }
    ul > li > a:nth-child(0) {
      margin-right: 10px;
    }
    ul > li > a:nth-child(1) {
      margin-left: 10px;
    }
    h1,
    h2,
    p,
    .signature {
      color : white;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1366px) {
  .home {
    .container {
      .vertical-center {
        margin-top: 60px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .home {
    .container {
      .contentTitle {
        p {
          font-size: 1.3rem;
        }
      }
  }
}
}

@media screen and (max-width: 500px) {
  .home {
    .container {
      .logo-container-2 {
        display: none;
      }
      .logo-container-1 {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
      }
      .vertical-center {
        margin-top: 100px;
      }
    }
  }
}

@media screen and (max-width: 395px) {
  .logo-1,
  .logo-2,
  .logo-3,
  .logo-4,
  .logo-5,
  .logo-6 {
    width: 10%;
  }
}
